<template>
  <div class="new-client panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">
      Создание нового клиента (физическое лицо)
    </h1>
    <form class="box" @submit.prevent="submit">
      <div class="columns">
        <div class="column">
          <b-field
            label="Фамилия"
            :type="{
              'is-danger': $v.lastName.$error || 'lastName' in serverValid,
              'is-success': !$v.lastName.$invalid && $v.lastName.$dirty,
            }"
            :message="{ 'Некорректно указана фамилия': $v.lastName.$error }"
          >
            <b-autocomplete
              placeholder="Фамилия"
              :data="lastNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="lastName"
              @typing="getDadata($event, 'lastName')"
              @select="setGender"
              @blur="
                onTouchField('lastName');
                removeKeyFromServerValid('lastName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Имя"
            :type="{
              'is-danger': $v.firstName.$error || 'firstName' in serverValid,
              'is-success': !$v.firstName.$invalid && $v.firstName.$dirty,
            }"
            :message="{ 'Некорректно указано имя': $v.firstName.$error }"
          >
            <b-autocomplete
              placeholder="Имя"
              :data="firstNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="firstName"
              @typing="getDadata($event, 'firstName')"
              @select="setGender"
              @blur="
                onTouchField('firstName');
                removeKeyFromServerValid('firstName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Отчество"
            :type="{
              'is-danger': $v.patronymic.$error || 'patronymic' in serverValid,
              'is-success': !$v.patronymic.$invalid && $v.patronymic.$dirty,
            }"
            :message="{ 'Некорректно указано отчество': $v.patronymic.$error }"
          >
            <b-autocomplete
              placeholder="Отчество"
              :data="patronymicData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="patronymic"
              @typing="getDadata($event, 'patronymic')"
              @select="setGender"
              @blur="
                onTouchField('patronymic');
                removeKeyFromServerValid('patronymic');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Пол">
            <b-radio-button
              v-model="gender"
              native-value="0"
              type="is-primary"
              expanded
            >
              <b-icon icon="male" size="is-medium"></b-icon>
              <span>Мужской</span>
            </b-radio-button>

            <b-radio-button
              v-model="gender"
              native-value="1"
              type="is-primary"
              expanded
            >
              <b-icon icon="female" size="is-medium"></b-icon>
              <span>Женский</span>
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-fifth">
          <b-field
            label="Телефон"
            :type="{
              'is-danger': $v.phone.$error || 'phone' in serverValid,
              'is-success': !$v.phone.$invalid && $v.phone.$dirty,
            }"
            :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
          >
            <IMaskComponent
              placeholder="+7(900)000-00-00"
              maxlength="16"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.phone.$error || 'phone' in serverValid,
                'is-success': !$v.phone.$invalid && $v.phone.$dirty,
              }"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim="phone"
              @blur="
                onTouchField('phone');
                removeKeyFromServerValid('phone');
              "
            />
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field
            label="Email"
            :type="{
              'is-danger': $v.email.$error || 'email' in serverValid,
              'is-success': !$v.email.$invalid && $v.email.$dirty,
            }"
            :message="{ 'Некорректно указан email': $v.email.$error }"
          >
            <b-input
              placeholder="example@mail.com"
              v-model.trim="email"
              @blur="
                onTouchField('email');
                removeKeyFromServerValid('email');
              "
            ></b-input>
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field
            label="Дата рождения"
            :type="{
              'is-danger': $v.birthday.$error || 'birthday' in serverValid,
              'is-success': !$v.birthday.$invalid && $v.birthday.$dirty,
            }"
            :message="{
              'Некорректно указана дата': $v.birthday.$error,
            }"
          >
            <IMaskComponent
              placeholder="00.00.0000"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.birthday.$error || 'birthday' in serverValid,
                'is-success': !$v.birthday.$invalid && $v.birthday.$dirty,
              }"
              :blocks="birthdayMaskBlocks"
              :mask="Date"
              v-model.trim="birthday"
              @blur="
                onTouchField('birthday');
                removeKeyFromServerValid('birthday');
              "
            />
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Место рождения"
            :type="{
              'is-danger': $v.birthCity.$error || 'birthCity' in serverValid,
              'is-success': !$v.birthCity.$invalid && $v.birthCity.$dirty,
            }"
            :message="{ 'Некорректно место рождения': $v.birthCity.$error }"
          >
            <b-input
              placeholder="Место рождения"
              v-model.trim="birthCity"
              @blur="
                onTouchField('birthCity');
                removeKeyFromServerValid('birthCity');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            label="Серия паспорта"
            :type="{
              'is-danger':
                $v.passportSeries.$error || 'passportSeries' in serverValid,
              'is-success':
                !$v.passportSeries.$invalid && $v.passportSeries.$dirty,
            }"
            :message="{ 'Серия паспорта 4 цифры': $v.passportSeries.$error }"
          >
            <IMaskComponent
              placeholder="0000"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger':
                  $v.passportSeries.$error || 'passportSeries' in serverValid,
                'is-success':
                  !$v.passportSeries.$invalid && $v.passportSeries.$dirty,
              }"
              :mask="'0000'"
              v-model.trim="passportSeries"
              @blur="
                onTouchField('passportSeries');
                removeKeyFromServerValid('passportSeries');
              "
            />
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Номер паспорта"
            :type="{
              'is-danger':
                $v.passportNumber.$error || 'passportNumber' in serverValid,
              'is-success':
                !$v.passportNumber.$invalid && $v.passportNumber.$dirty,
            }"
            :message="{ 'Номер паспорта 6 цифр': $v.passportNumber.$error }"
          >
            <IMaskComponent
              placeholder="000000"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger':
                  $v.passportNumber.$error || 'passportNumber' in serverValid,
                'is-success':
                  !$v.passportNumber.$invalid && $v.passportNumber.$dirty,
              }"
              :mask="'000000'"
              v-model.trim="passportNumber"
              @blur="
                onTouchField('passportNumber');
                removeKeyFromServerValid('passportNumber');
              "
            />
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Код подразделения"
            :type="{
              'is-danger':
                $v.passportIssueCode.$error ||
                'passportIssueCode' in serverValid,
              'is-success':
                !$v.passportIssueCode.$invalid && $v.passportIssueCode.$dirty,
            }"
            :message="{
              'Некорректно указан код подразделения':
                $v.passportIssueCode.$error,
            }"
          >
            <b-autocomplete
              placeholder="000-000"
              maxlength="7"
              :data="passportIssueCodeData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="passportIssueCode"
              @typing="getDadata($event, 'passportIssueCode')"
              @select="
                (option) =>
                  (passportIssue =
                    option !== null && 'data' in option
                      ? option.data.name
                      : passportIssue)
              "
              @blur="
                onTouchField('passportIssueCode');
                removeKeyFromServerValid('passportIssueCode');
              "
            >
              <template slot-scope="props">
                {{ props.option.data.code + ' ' + props.option.data.name }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-two-fifths">
          <b-field
            label="Кем выдан"
            :type="{
              'is-danger':
                $v.passportIssue.$error || 'passportIssue' in serverValid,
              'is-success':
                !$v.passportIssue.$invalid && $v.passportIssue.$dirty,
            }"
            :message="{
              'Некорректно заполнено поле': $v.passportIssue.$error,
            }"
          >
            <b-input
              placeholder="Кем выдан"
              v-model="passportIssue"
              @blur="
                onTouchField('passportIssue');
                removeKeyFromServerValid('passportIssue');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-fifth">
          <b-field
            label="Дата выдачи"
            :type="{
              'is-danger':
                $v.passportDate.$error || 'passportDate' in serverValid,
              'is-success': !$v.passportDate.$invalid && $v.passportDate.$dirty,
            }"
            :message="{
              'Некорректно указана дата': $v.passportDate.$error,
            }"
          >
            <IMaskComponent
              placeholder="00.00.0000"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger':
                  $v.passportDate.$error || 'passportDate' in serverValid,
                'is-success':
                  !$v.passportDate.$invalid && $v.passportDate.$dirty,
              }"
              :blocks="passportDateMaskBlocks"
              :mask="Date"
              v-model.trim="passportDate"
              @blur="
                onTouchField('passportDate');
                removeKeyFromServerValid('passportDate');
              "
            />
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Регион"
            :type="{
              'is-danger': $v.addrRegion.$error || 'addrRegion' in serverValid,
              'is-success': !$v.addrRegion.$invalid && $v.addrRegion.$dirty,
            }"
            :message="{ 'Некорректно указан регион': $v.addrRegion.$error }"
          >
            <b-autocomplete
              placeholder="Регион"
              :data="addrRegionData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="addrRegion"
              @typing="getDadata($event, 'addrRegion')"
              @select="setLocation"
              @blur="
                onTouchField('addrRegion');
                removeKeyFromServerValid('addrRegion');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Город"
            :type="{
              'is-danger': $v.addrCity.$error || 'addrCity' in serverValid,
              'is-success': !$v.addrCity.$invalid && $v.addrCity.$dirty,
            }"
            :message="{ 'Некорректно указан город': $v.addrCity.$error }"
          >
            <b-autocomplete
              placeholder="Город"
              :data="addrCityData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="addrCity"
              @typing="getDadata($event, 'addrCity')"
              @select="setLocation"
              @blur="
                onTouchField('addrCity');
                removeKeyFromServerValid('addrCity');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            label="Улица"
            :type="{
              'is-danger': $v.addrStreet.$error || 'addrStreet' in serverValid,
              'is-success': !$v.addrStreet.$invalid && $v.addrStreet.$dirty,
            }"
            :message="{ 'Некорректно указана улица': $v.addrStreet.$error }"
          >
            <b-autocomplete
              placeholder="Улица"
              :data="addrStreetData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="addrStreet"
              @typing="getDadata($event, 'addrStreet')"
              @select="setLocation"
              @blur="
                onTouchField('addrStreet');
                removeKeyFromServerValid('addrStreet');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Дом"
            :type="{
              'is-danger': $v.addrHouse.$error || 'addrHouse' in serverValid,
              'is-success': !$v.addrHouse.$invalid && $v.addrHouse.$dirty,
            }"
            :message="{ 'Некорректно указан дом': $v.addrHouse.$error }"
          >
            <b-autocomplete
              placeholder="Дом"
              :data="addrHouseData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="addrHouse"
              @typing="getDadata($event, 'addrHouse')"
              @select="setLocation"
              @blur="
                onTouchField('addrHouse');
                removeKeyFromServerValid('addrHouse');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            label="Квартира"
            :type="{
              'is-danger':
                $v.addrApartment.$error || 'addrApartment' in serverValid,
              'is-success':
                !$v.addrApartment.$invalid && $v.addrApartment.$dirty,
            }"
            :message="{
              'Некорректно указан квартира': $v.addrApartment.$error,
            }"
          >
            <b-input
              placeholder="Квартира"
              v-model.trim="addrApartment"
              @blur="
                onTouchField('addrApartment');
                removeKeyFromServerValid('addrApartment');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button type="is-info" native-type="submit">Создать</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { IMaskComponent } from 'vue-imask';
import { MaskedRange } from 'imask';
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';
import { helpers } from 'vuelidate/lib/validators';
const alphaNum = helpers.regex('alphaNum', /^\d{3}-\d{3}$/);

const birthdayMaskBlocks = {
  d: {
    mask: MaskedRange,
    placeholderChar: 'Д',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  m: {
    mask: MaskedRange,
    placeholderChar: 'М',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  Y: {
    mask: MaskedRange,
    placeholderChar: 'Г',
    from: dayjs().subtract(100, 'years').year(),
    to: dayjs().subtract(18, 'years').year(),
    maxLength: 4,
  },
};

const passportDateMaskBlocks = {
  d: {
    mask: MaskedRange,
    placeholderChar: 'Д',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  m: {
    mask: MaskedRange,
    placeholderChar: 'М',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  Y: {
    mask: MaskedRange,
    placeholderChar: 'Г',
    from: dayjs().subtract(40, 'years').year(),
    to: dayjs().year(),
    maxLength: 4,
  },
};

export default {
  name: 'NewClientPhysical',
  data() {
    return {
      lastNameData: [],
      firstNameData: [],
      patronymicData: [],
      passportIssueCodeData: [],
      addrRegionData: [],
      addrCityData: [],
      addrStreetData: [],
      addrHouseData: [],
      location: {},
      isFetching: false,
      phone: '',
      firstName: '',
      lastName: '',
      patronymic: '',
      gender: '',
      email: '',
      birthday: '',
      birthCity: '',
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      passportIssue: '',
      passportIssueCode: '',
      addrRegion: '',
      addrCity: '',
      addrStreet: '',
      addrHouse: '',
      isPassport: '1',
      addrApartment: '',
      birthdayMaskBlocks,
      passportDateMaskBlocks,
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    ip() {
      return this.$store.state.getme.ip;
    },
    noValid() {
      return this.$store.state.client.noValid;
    },
  },
  watch: {
    ip(val) {
      DadataAPI.getKladrId(val);
    },
    noValid(fields) {
      this.serverValid = { ...fields };
    },
    passportIssueCode(val) {
      if (val.length === 3) {
        this.passportIssueCode = val + '-';
      }
    },
  },
  methods: {
    setGender(option) {
      if (option !== null) {
        this.gender =
          option.data.gender !== 'UNKNOWN'
            ? option.data.gender === 'MALE'
              ? '0'
              : '1'
            : this.gender;
      }
    },
    setLocation(option) {
      this.location.city_fias_id = option.data.city_fias_id;
      this.location.country_iso_code = option.data.country_iso_code;
      this.location.region_fias_id = option.data.region_fias_id;
      this.location.region_iso_code = option.data.region_iso_code;
      this.location.street_fias_id = option.data.street_fias_id;
    },
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = {};

      switch (field) {
        case 'lastName':
        case 'firstName':
        case 'patronymic':
          data = await DadataAPI.getFIO({
            value,
            type: field,
            gender: this.gender,
          });
          break;

        case 'passportIssueCode':
          data = await DadataAPI.getFMSUnit({
            value,
          });
          break;

        case 'addrRegion':
        case 'addrCity':
        case 'addrStreet':
        case 'addrHouse':
          data = await DadataAPI.getAddress({
            value,
            type: field,
            location: this.location,
          });
          break;

        default:
          break;
      }

      this[field + 'Data'] = [];

      if (data.length) {
        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        firstName: this.firstName,
        lastName: this.lastName,
        patronymic: this.patronymic,
        gender: this.gender,
        email: this.email,
        birthday: this.birthday,
        birthCity: this.birthCity,
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
        passportDate: this.passportDate,
        passportIssue: this.passportIssue,
        passportIssueCode: this.passportIssueCode,
        addrRegion: this.addrRegion,
        addrCity: this.addrCity,
        addrStreet: this.addrStreet,
        addrHouse: this.addrHouse,
        addrApartment: this.addrApartment,
        isPassport: this.isPassport,
        type: 1,
      };

      this.$store.dispatch('client/newClient', clientData);
    },
  },
  validations: {
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    patronymic: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      // required,
    },
    phone: {
      minLength: minLength(16),
      maxLength: maxLength(16),
      // required,
    },
    email: {
      email,
      maxLength: maxLength(40),
    },
    passportSeries: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    passportNumber: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    passportIssueCode: {
      // required,
      minLength: minLength(7),
      maxLength: maxLength(7),
      alphaNum,
    },
    passportIssue: {
      // required,
      minLength: minLength(4),
    },
    passportDate: {
      // required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    birthday: {
      // required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    birthCity: {
      // required,
      minLength: minLength(4),
    },
    addrRegion: {
      // required,
      minLength: minLength(2),
    },
    addrCity: {
      // required,
      minLength: minLength(2),
    },
    addrStreet: {
      // required,
      minLength: minLength(2),
    },
    addrHouse: {
      minLength: minLength(1),
      // required,
    },
    addrApartment: {
      minLength: minLength(1),
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.new-client {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;
    }
  }
}
</style>
